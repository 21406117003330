//import { results } from '../../../data.js'
// import OrgService from "@/services/OrgService";
import axios from "axios";
//import { getOwnPropertySymbols } from 'core-js/core/object';

export const namespaced = true;
let localCache = localStorage.getItem('brews')
/*if (localCache === null || localCache == 'null') {
	console.log("SETTING INITIAL BREWS")
	localStorage.setItem('brews', JSON.stringify(results))
}*/


const runDownload = async () => {
	console.log('making axios request')
	const { data } = await axios.get('https://api.casperoktoberfest.com/api/v1/breweries');
	const brewData = data.data;
	//console.log(data);
	let temp = localStorage.getItem('brews')
	let localCache;
	if (temp != null && temp != "" && temp != "[]" && temp != undefined) {
		localCache = JSON.parse(temp)
	}
	let brews = [];

	console.log(brewData);
	if (brewData.length > 0) {
		for (var i = 0; i < brewData.length; i++) {
			let checked = false;
			if (localCache != null && localCache != "null" && localCache != "[]") {
				for (var x = 0; x < localCache.length; x++) {
					if (localCache[x].id == brewData[i].id) {
						if (localCache[x].checked == true) {
							checked = true;
						}
					}
				}
			}
			brews.push({ id: brewData[i].id, description: brewData[i].description, checked: checked, logoURL: brewData[i].logoURL, greyLogoURL: brewData[i].greyLogoURL, siteURL: brewData[i].siteURL, location: brewData[i].location });
		}
		console.log("Data consolidated")
	}
	//console.log("Brews: " + brews)
	localStorage.setItem('brews', JSON.stringify(brews))
	//commit("DOWNLOAD_BREWERIES", brews);
	return localStorage.getItem('brews');
}

export const state = {
	brewery: null,
	breweries: JSON.parse(localCache) || [],
	meta: null,
	links: null,
	loading: false,
	error: null,
};

export const mutations = {
	SET_BREWERY (state, payload) {
		payload.breweries.map(brewery => {
			console.log('COMPARE ID: ' + brewery.id + " TO " + payload.decodedString)
			if (brewery.id === payload.decodedString) {
				console.log('checked')
				brewery.checked = true;
			}
		})
		state.breweries = payload.breweries
		localStorage.setItem('brews', JSON.stringify(state.breweries))
	},
	SET_BREWERIES (state, breweries) {
		state.breweries = breweries;
		localStorage.setItem('brews', JSON.stringify(state.breweries))
	},
	SET_ONE (state, payload) {
		state.brewery = payload
	},
	SET_META (state, meta) {
		state.meta = meta;
	},
	SET_LINKS (state, links) {
		state.links = links
	},
	SET_LOADING (state, loading) {
		state.loading = loading;
	},
	SET_ERROR (state, error) {
		state.error = error;
	},
};

export const actions = {
	async downloadBreweries ({ commit }) {
		console.log("Downloading breweries")

		let localCache = await runDownload();

		//console.log("Cache: " + localCache)
		commit("SET_BREWERIES", JSON.parse(localCache))
	},
	async getBreweries ({ commit }) {
		commit("SET_LOADING", true);
		try {

			let temp = localStorage.getItem('brews')
			let localCache = JSON.parse(temp)
			console.log("LOCAL CACHE: " + localCache)
			if (localCache == null || localCache != "null" || localCache != "[]") {
				console.log("Downloading breweries")
				localCache = await runDownload();
				//console.log("Cache: " + localCache)
				commit("SET_BREWERIES", JSON.parse(localCache))

			}
			else {
				console.log('Setting breweries: ' + localCache)
				commit("SET_BREWERIES", JSON.parse(localCache))
			}
			//console.log('LS: ', localCache)
			commit("SET_LOADING", false);
			//console.log('RESPONSE.DATA.BREWERIES: ', results)
		} catch (error) {
			commit("SET_LOADING", false);
			commit("SET_BREWERIES", null);
		}
	},
	async scanBrew ({ commit }, decodedString) {
		commit("SET_LOADING", true);
		try {
			const temp = localStorage.getItem('brews')
			let breweries = JSON.parse(temp)
			commit("SET_BREWERY", { breweries: breweries, decodedString: decodedString })
			commit("SET_LOADING", false);
			console.log('RESPONSE.DATA.BREWERY: ', decodedString)
		} catch (error) {
			commit("SET_LOADING", false);
			commit("SET_BREWERIES", null);
		}
	},

	async getOne ({ commit }, id) {
		commit("SET_LOADING", true);
		try {
			for (let i = 0; i < state.breweries.length; i++) {
				if (state.breweries[i].id === parseInt(id)) {
					const brew = state.breweries[i];
					commit("SET_ONE", brew)
				}
			}
			commit("SET_LOADING", false);
			//console.log('RESPONSE.DATA.ONE: ', results)
		} catch (error) {
			commit("SET_LOADING", false);
			commit("SET_ONE", null);
		}
	},
};

export const getters = {
	breweries: (state) => {
		return state.breweries;
	},
	meta: (state) => {
		return state.meta;
	},
	links: (state) => {
		return state.links;
	},
	loading: (state) => {
		return state.loading;
	},
	error: (state) => {
		return state.error;
	},
};

