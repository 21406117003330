<template>
  <div class="page">
    <h2>{{ eventDate }}</h2>
    <v-img :lazy-src="require('@/assets/images/Oktoberfest_logo.png')" max-height="300" max-width="300"
      :src="require('@/assets/images/Oktoberfest_logo.png')" class="ma-2"></v-img><v-img
      :lazy-src="require('@/assets/images/Makeawish_logo.png')" max-height="300" max-width="300"
      :src="require('@/assets/images/Makeawish_logo.png')" class="ma-2"></v-img><v-btn
      :href="settings.wish_reveal_link" target="_blank" color="#fbb040">See the Wish Reveal</v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

// import { results } from '@/data.js'
export default {
  name: "Home",
  computed: {
    eventDate: function(){
      return dayjs(this.settings.event_date).format('dddd, MMMM Do, YYYY')
    },
    ...mapGetters("setting", ["settings"]),
  }
}
</script>
<style scoped>
.page {
  display:flex;
  flex-flow:column;
  justify-content:center;
  align-items: center;
}
a.v-btn {
  margin-top: 1.5rem;
  color: #121212;
}
</style>
