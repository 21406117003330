//import { results } from '../../../data.js'
// import OrgService from "@/services/OrgService";
import axios from "axios";

export const namespaced = true;
let localCache = localStorage.getItem('sponsors')
/*if (localCache === null || localCache == 'null') {
    console.log("SETTING INITIAL SPONSORS")
    localStorage.setItem('sponsors', JSON.stringify(results))
}*/

export const state = {
    sponsor: null,
    sponsors: JSON.parse(localCache) || [],
    meta: null,
    links: null,
    loading: false,
    error: null,
};

export const mutations = {
    SET_SPONSOR (state, payload) {
        payload.sponsor.map(index => {
            if (index.id === parseInt(payload.decodedString)) {
                index.checked = true;
            }
        })
        state.sponsors = payload.sponsor
        localStorage.setItem('sponsors', JSON.stringify(state.sponsors))
    },
    SET_SPONSORS (state, sponsors) {
        state.sponsors = sponsors;
        localStorage.setItem('sponsors', JSON.stringify(state.sponsors))
    },
    SET_ONE (state, payload) {
        state.sponsor = payload
    },
    SET_META (state, meta) {
        state.meta = meta;
    },
    SET_LINKS (state, links) {
        state.links = links
    },
    SET_LOADING (state, loading) {
        state.loading = loading;
    },
    SET_ERROR (state, error) {
        state.error = error;
    },
};

export const actions = {
    async getSponsors ({ commit }) {
        commit("SET_LOADING", true);
        try {
            const temp = localStorage.getItem('sponsors')
            if (temp != null) {
                commit("SET_SPONSORS", JSON.parse(temp))
            }
            else {
                const { data } = await axios.get('https://api.casperoktoberfest.com/api/v1/sponsors');
                commit("SET_SPONSORS", data.data)
            }
            console.log('LS: ', temp)
            commit("SET_LOADING", false);
            //console.log('RESPONSE.DATA.SPONSORS: ', results)
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_SPONSORS", null);
        }
    },

    async getBrew ({ commit }, decodedString) {
        commit("SET_LOADING", true);
        try {
            const temp = localStorage.getItem('sponsors')
            commit("SET_SPONSOR", { sponsor: JSON.parse(temp), decodedString: decodedString })
            commit("SET_LOADING", false);
            console.log('RESPONSE.DATA.SPONSOR: ', decodedString)
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_SPONSORS", null);
        }
    },

    async getOne ({ commit }, id) {
        commit("SET_LOADING", true);
        try {
            for (let i = 0; i < state.sponsors.length; i++) {
                if (state.sponsors[i].id == id) {
                    const brew = state.sponsors[i];
                    commit("SET_ONE", brew)
                }
            }
            commit("SET_LOADING", false);
            //console.log('RESPONSE.DATA.ONE: ', results)
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_ONE", null);
        }
    },

};

export const getters = {
    sponsors: (state) => {
        return state.sponsors;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};