import Vue from "vue";
import Vuex from "vuex";

import * as brew from "@/store/modules/breweries";
import * as sponsor from "@/store/modules/sponsors";
import * as location from "@/store/modules/locations";
import * as setting from "@/store/modules/settings";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,

    modules: {
        brew,
        sponsor,
        location,
        setting,
    },
});