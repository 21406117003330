import axios from "axios"
import dayjs from 'dayjs'

export const namespaced = true

let localCache = localStorage.getItem('settings')

console.log(localCache)

export const state = {
    settings: (localCache != "undefined" && JSON.parse(localCache)) || { 'updated': dayjs('2018-04-04'), 'event_date': dayjs(), 'wish_reveal_link': 'https://kalensolutions.com/wish-reveal/', 'donation_link': 'https://secure2.wish.org/site/SPageServer?pagename=donate_today&chid=043-000', 'map_url': 'https://oktoberfest-logos.s3.us-west-2.amazonaws.com/oktoberfest-map-2023.png', 'loaded': false },
    meta: null,
    links: null,
    loading: false,
    error: null,
};

export const mutations = {
    SET_SETTINGS (state, payload) {
        console.log(payload)
        state.settings = payload.settings
        localStorage.setItem('settings', JSON.stringify(state.settings))
    },
    SET_ONE (state, payload) {
        state.settings = payload
    },
    SET_META (state, meta) {
        state.meta = meta;
    },
    SET_LINKS (state, links) {
        state.links = links
    },
    SET_LOADING (state, loading) {
        state.loading = loading;
    },
    SET_ERROR (state, error) {
        state.error = error;
    },
};

export const actions = {
    async getSettings ({ commit }) {
        commit("SET_LOADING", true);
        console.log('Downloading Settings...');
        try {
            const temp = localStorage.getItem('settings')
            let set = {};
            if (temp != 'undefined') {
                JSON.parse(temp)
            }

            console.log("parsed set:")
            console.log(set)
            if (temp != null && set != null && typeof set === 'object' && Object.prototype.hasOwnProperty.call(set, 'loaded') && !set.loaded != false) {
                console.log('cached settings being loaded')
                commit("SET_SETTINGS", { settings: set })
            }
            else {
                const { data } = await axios.get('https://api.casperoktoberfest.com/api/v1/settings');
                let setting = data.data;
                setting.event_date = dayjs(setting.event_date)
                commit("SET_SETTINGS", { 'settings': setting })
            }
            console.log('LS: ', temp)
            commit("SET_LOADING", false);
            //console.log('RESPONSE.DATA.LOCATIONS: ', results)
        } catch (error) {
            console.log(error)
            commit("SET_LOADING", false);
            commit("SET_SETTINGS", null);
        }
    },
};

export const getters = {
    settings: (state) => {
        return state.settings;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};