import axios from "axios"

export const namespaced = true

let localCache = localStorage.getItem('locations')

export const state = {
    location: null,
    locations: JSON.parse(localCache) || [],
    meta: null,
    links: null,
    loading: false,
    error: null,
};

export const mutations = {
    SET_LOCATION (state, payload) {
        payload.location.map(index => {
            if (index.id === parseInt(payload.decodedString)) {
                index.checked = true;
            }
        })
        state.locations = payload.location
        localStorage.setItem('locations', JSON.stringify(state.locations))
    },
    SET_LOCATIONS (state, locations) {
        state.locations = locations;
        localStorage.setItem('locations', JSON.stringify(state.locations))
    },
    SET_ONE (state, payload) {
        state.location = payload
    },
    SET_META (state, meta) {
        state.meta = meta;
    },
    SET_LINKS (state, links) {
        state.links = links
    },
    SET_LOADING (state, loading) {
        state.loading = loading;
    },
    SET_ERROR (state, error) {
        state.error = error;
    },
};

export const actions = {
    async getLocations ({ commit }) {
        commit("SET_LOADING", true);
        try {
            const temp = localStorage.getItem('locations')
            if (temp != null) {
                commit("SET_LOCATIONS", JSON.parse(temp))
            }
            else {
                const { data } = await axios.get('https://api.casperoktoberfest.com/api/v1/locations');
                commit("SET_LOCATIONS", data.data)
            }
            console.log('LS: ', temp)
            commit("SET_LOADING", false);
            //console.log('RESPONSE.DATA.LOCATIONS: ', results)
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_LOCATIONS", null);
        }
    },

    async getLocation ({ commit }, decodedString) {
        commit("SET_LOADING", true);
        try {
            const temp = localStorage.getItem('locations')
            commit("SET_LOCATION", { location: JSON.parse(temp), decodedString: decodedString })
            commit("SET_LOADING", false);
            console.log('RESPONSE.DATA.LOCATION: ', decodedString)
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_LOCATIONS", null);
        }
    },

    async getOne ({ commit }, id) {
        commit("SET_LOADING", true);
        try {
            for (let i = 0; i < state.locations.length; i++) {
                if (state.locations[i].id == id) {
                    const brew = state.locations[i];
                    commit("SET_ONE", brew)
                }
            }
            commit("SET_LOADING", false);
            //console.log('RESPONSE.DATA.ONE: ', results)
        } catch (error) {
            commit("SET_LOADING", false);
            commit("SET_ONE", null);
        }
    },

};

export const getters = {
    locations: (state) => {
        return state.locations;
    },
    meta: (state) => {
        return state.meta;
    },
    links: (state) => {
        return state.links;
    },
    loading: (state) => {
        return state.loading;
    },
    error: (state) => {
        return state.error;
    },
};